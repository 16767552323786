<template>
  <h1>Resources</h1>
  <hr/>
  <div class="container-fluid">
    <div class="col" style=" vertical-align: top; padding: 1%;" id="gettingStarted">
      <h3 style="color: black;">Knowledge Base</h3>
      <ul >
          <li>
              <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57937701-about-project-para-expectations-common-questions" target="_blank" rel="noopener noreferrer">About Project Para - Expectations / Common Questions</a>
          </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664220-resources-for-getting-started" target="_blank" rel="noopener noreferrer">Site Access for Getting Started - A 4 Step Process</a>
        </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664359-myesu-and-canvas-systems-information" target="_blank" rel="noopener noreferrer">MyESU - Project Para and Canvas - 2 Systems Sharing Information</a>
        </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664424-registration-information" target="_blank" rel="noopener noreferrer">Registration Information - District/Institution, Para, Canvas, Certificates</a>
        </li>

        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664447-login-information" target="_blank" rel="noopener noreferrer">Login Information - Password Reset Para or Canvas with images</a>
        </li>
          <li>
              <a href="https://nebraska.jitbit.com/helpdesk/KB/View/58837746-options-to-fix-access-issues" target="_blank" rel="noopener noreferrer">Options to Fix Access Issues</a>
          </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664472-training-and-certification-information" target="_blank" rel="noopener noreferrer">Training
            and Certification Information</a>
        </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664552-part-1-registering-school-districts-institutions" target="_blank" rel="noopener noreferrer">Part 1: Registering School Districts/Institutions</a>
        </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664572-part-2-registering-paraeducators-paraprofessionals-participants" target="_blank" rel="noopener noreferrer">Part 2: Registering Paraeducators/Paraprofessionals Participants</a>
        </li>
        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664598-part-3-enrolling-in-canvas-accessing-paraeducator-self-study-training-materials" target="_blank" rel="noopener noreferrer">Part 3: Enrolling in Canvas & Accessing Paraeducator Self-Study Training Materials</a>
        </li>

        <li>
          <a href="https://nebraska.jitbit.com/helpdesk/KB/View/57664642-completing-accessing-certificates-for-title-i-sped-and-or-early-childhood-training" target="_blank" rel="noopener noreferrer">Part 4: Completing & Accessing Certificates for Title I, SPED, and/or Early Childhood Training</a>
        </li>
      </ul>
    </div>
  </div>
  <div style="padding: 30px;" id="requestHelp">
    <h2 style="color: black;">Have a more specific question?</h2>
    <router-link :to="{name: 'RequestHelp'}" style="font-size: 36px;">Contact Us</router-link>
  </div>
</template>

<style scoped>
.programbox {
  overflow: auto;
  font-size: 95%;
  width: 240px;
  float: left;
  margin: 1em 0.5em 1em 0.5em;
  padding: 1em;
  word-wrap: break-word;
  line-height: 1.2em;
  color: #000;
  border: 1px dotted blue;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}

.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0);
  border-radius: 0;
  padding: 1%;
  /*max-width: 800px;*/
  /*max-height: 550px;*/
  overflow-y: scroll;
  text-align: left;
  /*display: inline-block*/
}

.progname {
  font-family: "Open Sans", sans-serif;
  margin: 0 auto 0.5em auto;
  color: #29314a;
  text-align: left;
}

/*a {*/
/*  font-weight: bold;*/
/*  opacity: 1;*/
/*  color: #0000ab;*/
/*}*/
li {
    color: black;
}
ul {
    text-align: left;
    font-size: 20px;
    list-style-type: "\25B9";
}
</style>
<script setup>
</script>